<template>
  <nav style="position: fixed; top: 0; width: 100%; background-color: white; z-index: 1000;">
    <router-link to="/">Home</router-link> |
    <router-link to="/blog">Blogs</router-link> |
    <router-link to="/about">About</router-link>
  </nav>
  <div style="padding-top: 60px;">
    <router-view />
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
