<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/ByNakamoto.png')"
          class="my-3"
          contain
          height="150"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Welcome to ByNakamoto</h1>
      </v-col>

      <v-col class="mb-5" cols="12">
        <h2 class="headline font-weight-bold mb-5">External Links</h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in externalLinks"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
a {
  font-weight: bold;
  color: #2c3e50;
}

a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    externalLinks: [
      {
        text: "LinkedIn",
        href: "https://www.linkedin.com/in/nakamotoc60",
      },
      {
        text: "GitHub",
        href: "https://github.com/nakamotoc60",
      },
      {
        text: "Etsy",
        href: "https://www.etsy.com/shop/bynakamoto",
      },
      {
        text: "Instagram",
        href: "https://www.instagram.com/bynakamoto",
      },
    ],
  }),
};
</script>
